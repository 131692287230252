<template>
  <Layout>
    <!--<div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
      <div class="container">
        <VueSlickCarousel v-bind="sliderSetting">
          <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
            <div class="height-650 bg-overlay bg_image"
                 :style="{'background-image': `url(${slider.image})`}">
              <div class="container">
                <div class="row row&#45;&#45;30 align-items-center">
                  <div class="col-12">
                    <div class="inner text-center">
                      <h1 class="title" v-html="slider.title"/>
                      <p class="description" v-html="slider.description"/>
                      <div class="button-group mt&#45;&#45;30">
                        <a class="btn-default"
                           href="#">
                          PURCHASE NOW
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>

    </div>-->

    <div class="rwt-portfolio-area rn-section-gap" style="padding-top:25px !important;">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
                text-align="center"
                data-aos="fade-up"
                data-aos-delay="60"
            />
          </div>
        </div>
        <Product class="mt--20" :products="products" column="col-lg-3 col-6 mt--30"/>
      </div>
    </div>
  </Layout>
</template>

<script>
import Product from '../components/elements/product/Product'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import ProductMixin from "@/mixins/ProductMixin";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  name: "ProductPage",
  components: {Product, SectionTitle, Layout, Breadcrumb, VueSlickCarousel},
  // mixins: [ProductMixin],
  data() {
    return {
      products: [],
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
      sliderData: [
        {
          image: 'https://w.wallhaven.cc/full/j8/wallhaven-j8errw.png',
          title: 'Summer Season.',
          description: `We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials.`
        },
        {
          image: 'https://images3.alphacoders.com/785/785956.png',
          title: 'Winter Season.',
          description: `We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials.`
        },
        {
          image: 'https://www.clairvoyant.ai/hubfs/Clairvoyant-Banner-02-webp-1920x950-1.webp',
          title: 'Startup Agency.',
          description: `We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials.`
        },
      ],
    }
  },
  methods: {
    getData() {
      this.$axios.get('/api/website/products', {
        params: {
          siteName: "DayDayUp"
        },
      }).then(res => {
        console.log(res)
        if (res) {
          for (let product of res) {
            product.albums = JSON.parse(product.albums);
            product.contents = JSON.parse(product.contents);
            product.properties = JSON.parse(product.properties);
            product.tips = JSON.parse(product.tips);
          }

          this.products = res
        }
      })
    }
  },
  mounted() {
    this.getData()
  }
}
</script>