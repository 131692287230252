<template>
  <Layout>
    <!-- Start Contact Area  -->
    <div class="rwt-contact-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 mb--40">
            <SectionTitle
                text-align="center"
                subtitle="Warranty Form"
                title="Get Your Lifetime Warranty"
            />
          </div>
        </div>
        <div class="row mt--40 row--15">
          <div class="col-12">
            <form class="contact-form-1">
              <div class="form-group">
                <input
                    type="text"
                    name="amazonOrder"
                    placeholder="Amazon Order ID"
                    required
                    v-model="warranty.amazonOrderId"
                />
              </div>
              <div class="form-group">
                <input
                    type="text"
                    name="fullname"
                    placeholder="Your Name"
                    required
                    v-model="warranty.userName"
                />
              </div>
              <div class="form-group">
                <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    required
                    v-model="warranty.email"
                />
              </div>
              <div class="form-group">
                <button class="btn-default btn-large" @click.prevent="save">Submit Now</button>
              </div>

              <div class="form-group" v-html="showResult ? result : null"/>
            </form>
          </div>
          <!--<div class="col-lg-5 mt_md&#45;&#45;30 mt_sm&#45;&#45;30">
              <GoogleMap/>
          </div>-->
        </div>
      </div>
    </div>
    <!-- End Contact Area  -->
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Breadcrumb from '../components/elements/breadcrumb/Breadcrumb'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import Icon from "../components/icon/Icon";
import GoogleMap from "../components/elements/contact/GoogleMap";
import ContactForm from "../components/elements/contact/ContactForm";

export default {
  name: 'ContactPage',
  components: {ContactForm, GoogleMap, Icon, SectionTitle, Breadcrumb, Layout},
  data() {
    return {
      warranty: {
        siteName: 'DayDayUp',
        amazonOrderId: '',
        userName: '',
        email: '',
      },
      showResult: false,
      result: `<p class="success-message">Your Message has been successfully sent. I will contact you soon.</p>`
    }
  },
  methods: {
    save() {
      this.showResult = false;

      if (!/^\d{17}$/.test(this.warranty.amazonOrderId) && !/^\d{3}[-ー―‐～]\d{7}[-ー―‐～]\d{7}$/.test(this.warranty.amazonOrderId)) {
        this.result = `<p class="error-message">Amazon Order ID is invalid.</p>`
        this.showResult = true
        return
      }

      if (!/^[\d\D]{3,32}$/.test(this.warranty.userName)) {
        this.result = `<p class="error-message">Name is invalid.</p>`
        this.showResult = true
        return
      }

      if (!/^[\\.a-zA-Z0-9_-]+[@＠][a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(this.warranty.email) || this.warranty.email.length > 200) {
        this.result = `<p class="error-message">Email is invalid.</p>`
        this.showResult = true
        return
      }

      this.$axios.post('/api/website/warranty', this.warranty).then(() => {
        this.result = `<p class="success-message">Your Message has been successfully sent. I will contact you soon.</p>`
        this.showResult = true;
      }).catch(err => {
        this.result = `<p class="error-message">${err.response.data.message}</p>`
        this.showResult = true;
      });
    }
  }
}
</script>