<template>
<!--  <Layout header-class="header-left-align" nav-alignment="left"-->
  <Layout buy-button-class="btn-default btn-small">
    <div class="rwt-video-popup-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
                text-align="center"
                subtitle="Installation Tutorial"
            />
          </div>
        </div>
        <Video :video-data="videoData"/>
      </div>
    </div>

    <template v-for="(module, index) of modules">
      <div :key="`module${index}`" v-if="module.type === '幻灯片'" class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
        <VueSlickCarousel v-bind="sliderSetting">
          <div v-for="(slider, i) in module.content.list" :key="i" class="single-slide">
            <div class="height-950 bg-overlay bg_image"
                 :style="{'background-image': `url(${slider.image})`}">
              <div class="container">
                <div class="row row--30 align-items-center">
                  <div class="col-12">
                    <div class="inner text-center">
                      <h1 class="title" v-html="slider.title"/>
                      <p class="description" v-html="slider.subtitle"/>
                      <div class="button-group mt--30">
                        <a class="btn-default"
                           :href="`/product/detail/${slider.asin.asin}`">
                          {{ slider.text }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
      <div :key="`module${index}`" v-if="module.type === '商品列表'"  class="rwt-portfolio-area rn-section-gap">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <SectionTitle
                  text-align="center"
                  :subtitle="module.content.title"
                  :title="module.content.subtitle"
                  data-aos="fade-up"
                  data-aos-delay="60"
              />
            </div>
          </div>
          <simple-portfolio class="mt--20" :portfolio-data="module.content.list"
                            column="col-lg-3 col-6 mt--30"/>
        </div>
      </div>
      <div :key="`module${index}`" v-if="module.type === '用户评论'" class="rwt-testimonial-area rn-section-gap">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <SectionTitle
                  text-align="center"
                  :subtitle="module.content.title"
                  :title="module.content.subtitle"
                  description="We provide company and finance service for <br /> startups and company business."
                  data-aos="fade-up"
              />
            </div>
          </div>
          <div class="row row--15">
            <div class="col-lg-4 col-md-6 col-12 mt--30"
                 v-for="(testimonial, index) in module.content.list"
                 :key="index">
              <Testimonial :testimonial="testimonial"/>
            </div>
          </div>
        </div>
      </div>
      <div  :key="`module${index}`" v-if="module.type === '单品展示'" class="rwt-split-area rn-section-gap">
        <div class="container">
          <!--<div class="row">
            <div class="col-lg-12">
              <SectionTitle
                  text-align="center"
                  subtitle="Our Story"
                  title="This is where we begin."
                  data-aos="fade-up"
                  data-aos-delay="60"
              />
            </div>
          </div>-->
          <Split :split-data="module.content"/>
        </div>
      </div>
      <Separator/>
    </template>
    <!-- Start Slider Area -->
    <!--<div class="slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow">
      <VueSlickCarousel v-bind="sliderSetting">
        <div v-for="(slider, index) in sliderData" :key="index" class="single-slide">
          <div class="height-950 bg-overlay bg_image"
               :style="{'background-image': `url(${slider.image})`}">
            <div class="container">
              <div class="row row&#45;&#45;30 align-items-center">
                <div class="col-12">
                  <div class="inner text-center">
                    <h1 class="title" v-html="slider.title"/>
                    <p class="description" v-html="slider.description"/>
                    <div class="button-group mt&#45;&#45;30">
                      <a class="btn-default"
                         href="#">
                        PURCHASE NOW
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>-->



    <!--<Separator/>-->

    <!--<div class="rwt-portfolio-area rn-section-gap">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <SectionTitle
                text-align="center"
                subtitle="NEW ARRIVALS"
                title="Awesome New Arrivals"
                data-aos="fade-up"
                data-aos-delay="60"
            />
          </div>
        </div>
        <simple-portfolio class="mt&#45;&#45;20" :portfolio-data="products.slice(3, 6)"
                          column="col-lg-4 col-md-6 mt&#45;&#45;30"/>
      </div>
    </div>-->

    <!--<Separator/>-->

    <!-- Start Testimonial Area -->





    <!-- End Testimonial Area -->
  </Layout>
</template>

<script>
import Layout from '../components/common/Layout'
import Separator from '../components/elements/separator/Separator'
import SectionTitle from '../components/elements/sectionTitle/SectionTitle'
import ServiceOne from '../components/elements/service/ServiceOne'
import AboutFour from '../components/elements/about/AboutFour'
import VueSlickCarousel from 'vue-slick-carousel'
import BlogPostMixin from '../mixins/BlogPostMixin'
import BlogPost from '../components/blog/BlogPost'
import Gallery from '../components/elements/gallery/Gallery'
import Testimonial from '../components/elements/testimonial/Testimonial'
import PortfolioItemMixin from "@/mixins/PortfolioItemMixin";
import SimplePortfolio from "@/components/elements/portfolio/SimplePortfolio";
import productMixin from "@/mixins/ProductMixin";
import Split from "@/components/elements/split/Split";
import Video from '@/components/elements/video/Video.vue'

export default {
  name: "HomePage",
  components: {
    Video,
    Testimonial,
    Gallery, BlogPost, AboutFour, ServiceOne, SectionTitle, Separator, Layout, VueSlickCarousel, SimplePortfolio, Split
  },
  mixins: [BlogPostMixin, productMixin],
  data() {
    return {
      videoData: [
        {
          thumb: require(`@/assets/images/cover.png`),
          src: 'https://www.youtube.com/watch?v=HpNMK2wh4S4',
        },
      ],
      modules: [],
      sliderSetting: {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        responsive: [
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 993,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 580,
            settings: {
              slidesToShow: 1,
            }
          },
          {
            breakpoint: 481,
            settings: {
              slidesToShow: 1,
            }
          }
        ]
      },
      sliderData: [
        {
          image: 'https://w.wallhaven.cc/full/j8/wallhaven-j8errw.png',
          title: 'Summer Season.',
          description: `We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials.`
        },
        {
          image: 'https://images3.alphacoders.com/785/785956.png',
          title: 'Winter Season.',
          description: `We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials.`
        },
        {
          image: 'https://www.clairvoyant.ai/hubfs/Clairvoyant-Banner-02-webp-1920x950-1.webp',
          title: 'Startup Agency.',
          description: `We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials.`
        },
      ],
      galleryData: [
        {
          id: 1,
          thumb: 'https://www.lamicall.com/wp-content/uploads/2022/03/foldable-phone-holder-for-desk-dp13-black-1.png',//require(`@/assets/images/portfolio/portfolio-01.jpg`),
          src: 'https://www.lamicall.com/wp-content/uploads/2020/04/lamicall-bike-phone-mount-BM02-black-1.jpg',//require(`@/assets/images/portfolio/portfolio-01.jpg`),
          type: 'image'
        },
        {
          id: 2,
          thumb: require(`@/assets/images/portfolio/portfolio-02.jpg`),
          src: 'https://www.youtube.com/watch?v=d0tU18Ybcvk',
          type: 'video'
        },
        {
          id: 3,
          thumb: require(`@/assets/images/portfolio/portfolio-03.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-03.jpg`),
          type: 'image'
        },
        {
          id: 4,
          thumb: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-04.jpg`),
          type: 'image'
        },
        {
          id: 5,
          thumb: require(`@/assets/images/portfolio/portfolio-05.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-05.jpg`),
          type: 'image'
        },
        {
          id: 6,
          thumb: require(`@/assets/images/portfolio/portfolio-06.jpg`),
          src: require(`@/assets/images/portfolio/portfolio-06.jpg`),
          type: 'image'
        }
      ],
      testimonialData: [
        {
          form: 'Poland',
          description: 'My hats are off to the engineers who designed this. For me it is one of those small things in life that makes it all more fun. Thank you for a job well done!',
          name: 'Douglas Henderson',
          subtitle: 'App Developer',
          image: 'https://www.lamicall.com/wp-content/uploads/2021/07/male-2634974_640.jpg'
        },
        {
          form: 'Germany',
          description: 'I can finally use my iPhone in bed without having to hold it for hours, *cramping my arms and hands*. Watching videos is a breeze.',
          name: 'Veronika Bautista',
          subtitle: 'App Developer',
          image: 'https://www.lamicall.com/wp-content/uploads/2021/07/smiling-4654734_640.jpg'
        },
        {
          form: 'USA',
          description: 'Since the virtual learning this school year, I was looking for a Laptop stand for my five years old daughter. This stand is straightforward to install, use the tools they provided, and it is very stable and a perfect size to my daughter\'s laptop (11") as well as my Macbook Pro (16").',
          name: 'Sinead Tierney',
          subtitle: 'App Developer',
          image: 'https://www.lamicall.com/wp-content/uploads/2021/07/females-1450050_640.jpg'
        },
      ],
      splitOneData: {
        image: 'https://www.lamicall.com/wp-content/uploads/2021/11/Lamicall-team-members.jpg',
        // title: 'Our Story',
        // list: [
        //   'This is where we begin',
        // ],
        btn: {
          text: 'Learn More',
          link: '/contact'
        }
      },
    }
  },
  methods: {
    getData() {
      this.$axios.get("/api/website/modules", {
        params: {
          siteName: 'DayDayUp',
          page: '首页'
        }
      }).then(res => {
        for (let item of res) {
          item.content = JSON.parse(item.content);
        }

        this.modules = res;
      })
    }
  },
  mounted() {
    this.getData();
  }
}
</script>